const konwertujSlowa = (liczba) => {
  const jednosci = ["", "jeden", "dwa", "trzy", "cztery", "pięć", "sześć", "siedem", "osiem", "dziewięć"];
  const nascie = ["dziesięć", "jedenaście", "dwanaście", "trzynaście", "czternaście", "piętnaście", "szesnaście", "siedemnaście", "osiemnaście", "dziewiętnaście"];
  const dziesiatki = ["", "", "dwadzieścia", "trzydzieści", "czterdzieści", "pięćdziesiąt", "sześćdziesiąt", "siedemdziesiąt", "osiemdziesiąt", "dziewięćdziesiąt"];
  const setki = ["", "sto", "dwieście", "trzysta", "czterysta", "pięćset", "sześćset", "siedemset", "osiemset", "dziewięćset"];
  const grupy = [
    ["", "", ""],
    [" tysiąc", " tysiące", " tysięcy"],
    [" milion", " miliony", " milionów"],
    [" miliard", " miliardy", " miliardów"]
  ];

  if (isNaN(liczba) || liczba === null) return "Niepoprawna liczba";

  let wynikTekst = '';
  let liczbaAbs = Math.abs(liczba);
  if (liczbaAbs === 0) return "zero";

  let g = 0;
  while (liczbaAbs > 0) {
    const s = Math.floor((liczbaAbs % 1000) / 100);
    const d = Math.floor((liczbaAbs % 100) / 10);
    const j = Math.floor(liczbaAbs % 10);

    let n = 0;
    let czesc = "";

    if (d === 1 && j > 0) {
      czesc = `${setki[s]} ${nascie[j]}`;
    } else {
      czesc = `${setki[s]} ${dziesiatki[d]} ${jednosci[j]}`;
    }

    let k = (j === 1 && d + s === 0) ? 0 : (j >= 2 && j <= 4 ? 1 : 2);
    if (s + d + j > 0) {
      wynikTekst = `${czesc.trim()}${grupy[g][k]} ${wynikTekst}`;
    }

    g++;
    liczbaAbs = Math.floor(liczbaAbs / 1000);
  }

  return liczba < 0 ? `minus ${wynikTekst.trim()}` : wynikTekst.trim();
};

export default konwertujSlowa;
