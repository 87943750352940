import React, { useState } from 'react';
import html2pdf from 'html2pdf.js';
import konwertujSlowa from './slowa';

const Form = () => {

  const today = new Date();
  const formattedDate = `${String(today.getDate()).padStart(2, '0')}.${String(today.getMonth() + 1).padStart(2, '0')}.${today.getFullYear()}`;

  const [formData, setFormData] = useState({
    imie: '',
    nazwisko: '',
    imie2: '',
    nazwisko2: '',
    sprzedaz: '',
    cena: '',
    termin: '',
    miasto: '',
    date: formattedDate,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const generatePDF = () => {
    const cenaNaSlowa = konwertujSlowa(parseFloat(formData.cena));

    const content = `
      <div style="font-family: Arial, sans-serif; padding: 20px;">
        <p style="text-align: right;">${formData.miasto}, ${formData.date}</p>
        <h3 style="text-align: center; text-transform: uppercase;">Umowa przedwstępna sprzedaży nieruchomości</h3>
        <p>Pomiędzy: </p>
        <p>1. ${formData.imie} ${formData.nazwisko}</p>
        <p>zwanym dalej <strong>Sprzedającym</strong></p>
        <p>a</p>
        <p>2. ${formData.imie2} ${formData.nazwisko2}</p>
        <p>zwanym dalej <strong>Kupującym</strong></p>
        <p>o następującej treści:</p>
        <br />
        <p style="text-align: center;">§1</p>
        <p>Przedmiotem umowy jest zobowiązanie do zawarcia umowy sprzedaży: ${formData.sprzedaz}</p>
        <br />
        <p style="text-align: center;">§2</p>
        <p>Strony postanawiają, że przyrzeczona umowa sprzedaży zostanie zawarta do dnia ${formData.termin} za cenę ${formData.cena} zł (słownie: ${cenaNaSlowa} złotych).</p>
      </div>
    `;

    const options = {
      margin: 1,
      filename: 'formularz.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait' },
    };

    html2pdf().set(options).from(content).save();
  };

  return (
    <div>
      <form onSubmit={(e) => e.preventDefault()}>
        <label>
          Do kiedy umowa jest ważna:
          <input type="date" name="termin" value={formData.termin} onChange={handleChange} />
        </label>
        <br />
        <label>
          Cena:
          <input type="number" name="cena" value={formData.cena} onChange={handleChange} /> zł
        </label>
        <br />
        <label>
          Imię (Sprzedający):
          <input type="text" name="imie" value={formData.imie} onChange={handleChange} />
        </label>
        <br />
        <label>
          Nazwisko (Sprzedający):
          <input type="text" name="nazwisko" value={formData.nazwisko} onChange={handleChange} />
        </label>
        <br />
        <label>
          Imię (Kupujący):
          <input type="text" name="imie2" value={formData.imie2} onChange={handleChange} />
        </label>
        <br />
        <label>
          Nazwisko (Kupujący):
          <input type="text" name="nazwisko2" value={formData.nazwisko2} onChange={handleChange} />
        </label>
        <br />
        <label>
          Przedmiot sprzedaży:
          <textarea name="sprzedaz" value={formData.sprzedaz} onChange={handleChange}></textarea>
        </label>
        <br />
        <label>
          Miasto:
          <input type="text" name="miasto" value={formData.miasto} onChange={handleChange} />
        </label>
        <br />
        <label>
          Data - dzisiejsza:
          <input type="date" name="date" value={formData.date} onChange={handleChange} />
        </label>
        <br />
        <button type="button" onClick={generatePDF} style={{ marginTop: '10px' }}>
          Generuj PDF
        </button>
      </form>
    </div>
  );
};

export default Form;